import React, { Component } from "react";
import {connect} from "react-redux";
import fetchAirport from "../../../stateManager/fetch/airport";
// import fetchAirport from "@/stateManager/fetch/airport";
import AirlineBlock from "./segment/airline-block";
import moment from "moment";
// import bootstrapCss from 'bootstrap/dist/css/bootstrap.css'
// import bootstrap from "";

class Segments extends Component {

  state = {};

  constructor(props){
    super(props);
  }

  getAirport = (code) => {
    let airport = {};

    try {
      airport = this.props.airports[code];
      if(airport === undefined){
        throw new Error('Airport not found in the state');
      }
    } catch (ex) {
      fetchAirport(code);
      airport = this.props.empty_airport;
    }

    return airport;
  }

  render() {
    var segments = this.props.journeys[0].segments;
    // journeyHtml =[];
    // for journey in thi
    return (
      <React.Fragment>
        {segments.map((segment, index, array) => {
          // console.log(index);
          // let departure_time = new Date(segment.departure.at);
          // let arrival_time = new Date(segment.arrival.at);
          // console.log(departure_time);
          return (
            <React.Fragment key={index}>
              <div className="w3-col w3-display-container">
                <div className="w3-col s4" style={{ textAlign: "right" }}>
                  <span className="hightile-a">{segment.departure.code}</span>{" "}
                  <strong>{moment(segment.departure.at).format('HH:mm')}</strong>
                  <br />
                  {moment(segment.departure.at).format('DD MMM YYYY')}
                  <br />
                  {this.getAirport(segment.departure.code).name}, {this.getAirport(segment.departure.code).city_name}
                  <br />
                  Terminal: {segment.departure.terminal}
                </div>

                <div className="w3-col s4" style={{ textAlign: "center" }}>
                  <AirlineBlock segment={segment}/>
                </div>

                <div className="w3-col s4">
                  <strong>{moment(segment.arrival.at).format('HH:mm')}</strong>{" "}
                  <span className="hightile-a">{segment.arrival.code}</span>
                  <br />
                  {moment(segment.arrival.at).format('DD MMM YYYY')}
                  <br />
                  {this.getAirport(segment.arrival.code).name}, {this.getAirport(segment.arrival.code).city_name}
                  <br />
                  Terminal: {segment.arrival.terminal}
                </div>
              </div>
              <div className="clear"/>
              <div>{index !== array.length-1? <hr/> : ""}</div>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    empty_airport: state.empty_airport,
    airports: state.airports
  }
}

export default connect(mapStateToProps)(Segments);
