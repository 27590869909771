// import {combineReducers} from "redux";
import moment from "moment";

const defaultSearches = {
    recent: [],
    latest: {
        source: "DEL",
        destin: "BOM",
        depart: moment().add(5, 'days'),
        return: moment().add(12, 'days'),
        adult: 1,
        child: 0,
        infant: 0,
        class: "e"
    }
}

function searchesReducers(searches = defaultSearches, action) {
    if(action.type === 'NEW_SERCH'){
        let searchesCopy = Object.assign({}, searches);
        // searches.latest = action.search_filter;
        searchesCopy.recent.push(searches.latest);
        localStorage.setItem('searches', JSON.stringify(searchesCopy));
        return searchesCopy;
    }

    if(searches.latest === [] && localStorage.getItem('searches') !== 'undefined'){
        return JSON.parse(localStorage.getItem('searches'));
    }
    return {
        recent: recentSearchReducer(searches.recent, action),
        latest: latestSearchReducer(searches.latest, action)
    };
}

function recentSearchReducer(recent, action){
    // if(action.type === 'NEW_SERCH'){

    // }
    return recent;
}

function latestSearchReducer(latest, action){
    if(action.type === 'SEARCH_PARAM_CHANGED'){
        return Object.assign({}, latest, action.param)
    }
    return latest;
}

export default searchesReducers;
