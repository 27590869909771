import React, { Component } from "react";

class HeaderA extends Component {
  state = {};
  render() {
    let header_a_classes = "header-a";
    return (
      <div className={header_a_classes}>
        <div className="wrapper-padding">
          <div className="header-phone">
            <span>1 - 555 - 555 - 555</span>
          </div>
          <div className="header-account">
            <a href="http://google.com">My account</a>
          </div>
          <div className="header-social">
            <a href="http://google.com" className="social-twitter"  >&nbsp;</a>
            <a href="http://google.com" className="social-facebook"  >&nbsp;</a>
            <a href="http://google.com" className="social-vimeo"  >&nbsp;</a>
            <a href="http://google.com" className="social-pinterest"  >&nbsp;</a>
            <a href="http://google.com" className="social-instagram"  >&nbsp;</a>
          </div>
          <div className="header-viewed">
            <a href="http://google.com" className="header-viewed-btn">
              recently viewed
            </a>

            <div className="viewed-drop">
              <div className="viewed-drop-a">
                <div className="viewed-item">
                  <div className="viewed-item-l">
                    <a href="http://google.com">
                      <img alt="" src="img/v-item-01.jpg" />
                    </a>
                  </div>
                  <div className="viewed-item-r">
                    <div className="viewed-item-lbl">
                      <a href="http://google.com">Andrassy Thai Hotel</a>
                    </div>
                    <div className="viewed-item-cat">location: thailand</div>
                    <div className="viewed-price">152$</div>
                  </div>
                  <div className="clear" />
                </div>
                <div className="viewed-item">
                  <div className="viewed-item-l">
                    <a href="http://google.com">
                      <img alt="" src="img/v-item-02.jpg" />
                    </a>
                  </div>
                  <div className="viewed-item-r">
                    <div className="viewed-item-lbl">
                      <a href="http://google.com">Ermin's Hotel</a>
                    </div>
                    <div className="viewed-item-cat">location: dubai</div>
                    <div className="viewed-price">300$</div>
                  </div>
                  <div className="clear" />
                </div>
                <div className="viewed-item">
                  <div className="viewed-item-l">
                    <a href="http://google.com">
                      <img alt="" src="img/v-item-03.jpg" />
                    </a>
                  </div>
                  <div className="viewed-item-r">
                    <div className="viewed-item-lbl">
                      <a href="http://google.com">
                        Best Western Hotel Reither
                      </a>
                    </div>
                    <div className="viewed-item-cat">location: berlin</div>
                    <div className="viewed-price">2300$</div>
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
          <div className="header-lang">
            <a href="http://google.com">
              <img alt="" src="img/en.gif" />
            </a>
            <div className="langs-drop">
              <div>
                <a href="http://google.com" className="langs-item en">
                  english
                </a>
              </div>
              <div>
                <a href="http://google.com" className="langs-item fr">
                  francais
                </a>
              </div>
              <div>
                <a href="http://google.com" className="langs-item de">
                  deutsch
                </a>
              </div>
              <div>
                <a href="http://google.com" className="langs-item it">
                  italiano
                </a>
              </div>
            </div>
          </div>
          <div className="header-curency">
            <a href="http://google.com">USD</a>
            <div className="curency-drop">
              <div>
                <a href="http://google.com">usd</a>
              </div>
              <div>
                <a href="http://google.com">Eur</a>
              </div>
              <div>
                <a href="http://google.com">GBR</a>
              </div>
            </div>
          </div>
          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default HeaderA;
