
const defaultAirports ={};

let airportsReducer = (airports =defaultAirports, action) => {
    if(action.type === 'UPDATE_AIRPORT'){
        localStorage.setItem(action.code, JSON.stringify(action.data));
        return Object.assign({}, airports, {
            [action.code]:action.data
        });
    }
    return airports;
};

const defaultEmptyAirport = {
    name: 'Airport name',
    city_name: 'City',
    code: 'N/A'
};

export let emptyAirport = (state = defaultEmptyAirport, action) =>{
    return state;
}


export default airportsReducer;
