import React, { Component } from "react";

class SearchTab extends Component {
  state = {};
  active = false;
  content;
  render() {
    let tabClassName = "search-tab-content";
    let tabDisplay = { display: "none" };
    if (this.props.active === true) {
      tabDisplay = { display: "block" };
    }
    let TabContent = this.props.content.tabItem;
    return (
      <div className={tabClassName} style={tabDisplay}>
        <TabContent />
      </div>
    );
  }
}

export default SearchTab;
