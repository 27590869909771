import {combineReducers} from "redux";
import searchesReducer from "./reducers/searches";
import airportsReducer, { emptyAirport } from "./reducers/airports";
import airlinesReducer, { emptyAirline } from "./reducers/airlines";
import cabinTypes, { emptyCabin } from "./reducers/cabins";
import searchResultReducer from "./reducers/result";

const appReducer = combineReducers({
    searches: searchesReducer,
    result: searchResultReducer,
    airports: airportsReducer,
    empty_airport:emptyAirport,
    airlines: airlinesReducer,
    empty_airline:emptyAirline,
    cabins: cabinTypes,
    empty_cabin: emptyCabin
});

export default appReducer;
