import store, {dispatch} from './../store';

let fetchAirport = (code) => {
      try{
            let in_state = store.getState().airports[code];
            if(in_state !== undefined){
                  return in_state;
            }
      }catch(ex){

      }
      let in_storage = localStorage.getItem(code);
      if(in_storage != null){
            return dispatch({ type: 'UPDATE_AIRPORT', code: code, data: JSON.parse(in_storage) });
      }
      let url = process.env.REACT_APP_API_BASE_URL + '/airport/' + code;
      if(localStorage.getItem('in_process'+url) !== null){
            return false;
      }
      localStorage.setItem('in_process'+url, 'running');
      fetch(url)
            .then(response => response.json())
            .then(json => {
                  if(json.code === 200){
                        localStorage.removeItem('in_process'+url);
                        return dispatch({ type: 'UPDATE_AIRPORT', code: code, data: json.data });
                  }
            }, error => console.log(error));
}

export default fetchAirport;
