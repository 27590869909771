import React, { Component } from "react";

class Hotel extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        {/* <div className="search-tab-content"> */}
        <div className="page-search-p">
          {/* <!-- // --> */}
          <div className="search-large-i">
            {/* <!-- // --> */}
            <div className="srch-tab-line no-margin-bottom">
              <label>Place / hotel name</label>
              <div className="input-a">
                <input type="text" placeholder="Example:france" />
              </div>
            </div>
            {/* <!-- // -->							 */}
          </div>
          {/* <!-- \\ -->
              <!-- // --> */}
          <div className="search-large-i">
            {/* <!-- // --> */}
            <div className="srch-tab-line no-margin-bottom">
              <div className="srch-tab-left">
                <label>Check in</label>
                <div className="input-a">
                  <input
                    type="text"
                    className="date-inpt"
                    placeholder="mm/dd/yy"
                  />{" "}
                  <span className="date-icon" />
                </div>
              </div>
              <div className="srch-tab-right">
                <label>Check out</label>
                <div className="input-a">
                  <input
                    type="text"
                    className="date-inpt"
                    placeholder="mm/dd/yy"
                  />{" "}
                  <span className="date-icon" />
                </div>
              </div>
              <div className="clear" />
            </div>
            {/* <!-- \\ -->						 */}
          </div>
          {/* <!-- \\ -->
              <!-- // --> */}
          <div className="search-large-i">
            {/* <!-- // --> */}
            <div className="srch-tab-line no-margin-bottom">
              <div className="srch-tab-3c">
                <label>Rooms</label>
                <div className="select-wrapper">
                  <select className="custom-select">
                    <option>--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
              <div className="srch-tab-3c">
                <label>adult</label>
                <div className="select-wrapper">
                  <select className="custom-select">
                    <option>--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
              <div className="srch-tab-3c">
                <label>Child</label>
                <div className="select-wrapper">
                  <select className="custom-select">
                    <option>--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
              <div className="clear" />
            </div>
            {/* <!-- \\ -->						 */}
          </div>
          {/* <!-- \\ --> */}
          <div className="clear" />

          {/* <!-- // advanced // --> */}
          <div className="search-asvanced">
            {/* <!-- // --> */}
            <div className="search-large-i">
              {/* <!-- // --> */}
              <div className="srch-tab-line no-margin-bottom">
                <div className="srch-tab-left">
                  <label>hotel stars</label>
                  <div className="input-a">
                    <input type="text" placeholder="--" />
                  </div>
                </div>
                <div className="srch-tab-right">
                  <label>Price</label>
                  <div className="input-a">
                    <input type="text" placeholder="--" />
                  </div>
                </div>
                <div className="clear" />
              </div>
              {/* <!-- \\ -->							 */}
            </div>
            {/* <!-- \\ -->
                  <!-- // --> */}
            <div className="search-large-i">
              {/* <!-- // --> */}
              <div className="srch-tab-line no-margin-bottom">
                <label>Property type</label>
                <div className="select-wrapper">
                  <select className="custom-select">
                    <option>--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
              {/* <!-- \\ -->							 */}
            </div>
            {/* <!-- \\ -->
                  <!-- // --> */}
            <div className="search-large-i">
              {/* <!-- // --> */}
              <div className="srch-tab-line no-margin-bottom">
                <label>Rating</label>
                <div className="select-wrapper">
                  <select className="custom-select">
                    <option>--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
              {/* <!-- \\ -->								 */}
            </div>
            {/* <!-- \\ --> */}
            <div className="clear" />
          </div>
          {/* <!-- \\ advanced \\ --> */}
        </div>
        <footer className="search-footer">
          <a href="http://google.com" className="srch-btn">
            Search
          </a>
          <span className="srch-lbl">Advanced Search options</span>
          <div className="clear" />
        </footer>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default Hotel;
