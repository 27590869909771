import React from "react";
import ReactDOM from "react-dom";
import Router from "react-router-component";

import './scss/bootstrap.scss';

import "w3-css/w3.css";
import "jquery-ui/themes/base/all.css";
import "./css/style.css";
import "./css/custom.css";
import MainPage from "./components/pages/main-page";
import Flights from "./components/pages/Flights";
import PageNotFound from "./components/pages/404";
import dotenv from 'dotenv'
import store from './stateManager/store';
import { Provider } from 'react-redux';

// import "react-datepicker/dist/react-datepicker-cssmodules.css";
// import "react-datepicker/dist/react-datepicker.css";
dotenv.config();

let Locations = Router.Locations;
let Location = Router.Location;
let NotFound = Router.NotFound;

ReactDOM.render(
  <Provider store={store}>
    <Locations>
      <Location path="/" handler={MainPage} />
      <Location path="/flights/:search" handler={Flights} />
      <NotFound handler={PageNotFound} />
      {/* <Location
        path={/\/friends\/(\d+)\/(photos|wall)/}
        handler={FriendsPage}
        urlPatternOptions={["id", "pageName"]}
      /> */}
    </Locations>
  </Provider>,
  document.getElementById("root")
);
