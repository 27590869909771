import React, { Component } from "react";

class Tabs extends Component {
  state = {
    tab_list: this.props.children,
    activeTab: 0
  };

  tabClicked(clickedIndex) {
    this.setState(state => ({
      activeTab: clickedIndex
    }));
  }

  render() {
    const { tab_list } = this.state;

    return (
      <div className="content-tabs">
        <div className="content-tabs-head">
          <nav>
            <ul>
              {tab_list.map((tab, index) => {
                let activeClass =
                  index === this.state.activeTab ? " active" : " ";
                return (
                  <li onClick={e => this.tabClicked(index)} key={index}>
                    <a className={activeClass} href="javascript:void(0);">
                      {tab.props.label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>

          <div className="clear" />
        </div>
        <div className="content-tabs-body">
          {tab_list[this.state.activeTab].props.children ||
            tab_list[this.state.activeTab].props.content}
          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default Tabs;

export class Tab extends Component {
  state = {};
  render() {
    return <React.Fragment />;
  }
}
