import store, {dispatch} from "../store";

// import { type } from "os";

let fetchAirline = (iata_code) => {

      try{
            let in_state = store.getState().airlines[iata_code];
            if(in_state !== undefined){
                  return in_state;
            }
      }catch(ex){

      }

      let in_storage = localStorage.getItem(iata_code);
      if(in_storage != null){
            return dispatch({ type: 'UPDATE_AIRLINE', iata_code: iata_code, data: JSON.parse(in_storage) });
      }
      let url = process.env.REACT_APP_API_BASE_URL + '/airline/' + iata_code;
      if(localStorage.getItem('in_process'+url) !== null){
            return false;
      }
      localStorage.setItem('in_process'+url, 'running');
      fetch(url)
            .then(response => response.json())
            .then(json => {
                  if(json.code === 200){
                        localStorage.removeItem('in_process'+url);
                        return dispatch({ type: 'UPDATE_AIRLINE', iata_code: iata_code, data: json.data });
                  }
            }, error => console.log(error));
}

export default fetchAirline;
