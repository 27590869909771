
let defaultResult = {
    error: false,
    isLoaded: false,
    filter_options: {
        airlines: []
    },
    filters: {
        airlines: []
    },
    items: {
        all: [],
        filtered: []
    }
  };

let searchResultReducer = (result =defaultResult, action) => {
    if(action.type === 'UPDATE_SEARCH_RESULT'){
        return {...result, isLoaded: true, error: false,
            filter_options: {
                airlines: extractUniqueAirlines(action.data)
            },
            filters: filterReducer(result.filters, action),
            items: {
                all: action.data,
                filtered: filteredResultReducer(result.filtered, {type: 'APPLY_SEARCH_FILTER', data: action.data, filters: result.filters})
            }
        }
    }
    if(action.type === 'SEARCH_RESULT_FAILED'){
        return {...result, isLoaded: true, error: true}
    }

    // return result;
    return {
        error: result.error,
        isLoaded: result.isLoaded,
        filter_options: result.filter_options,
        filters: filterReducer(result.filters, action),
        items: {
            all: result.items.all,
            filtered: filteredResultReducer(result.items.filtered, action)
        }
    };
};

let filteredResultReducer = (filtered=[], action) => {
    if(action.type === 'APPLY_SEARCH_FILTER'){
        let filters = action.filters;
        // console.log(filters);
        return action.data.filter((item, index) => {
            //Airline Filter
            if(filters.airlines.length !== 0){
                let selectedAirlinesInOption = item.journeys.map(journey =>{
                    return filters.airlines.indexOf(journey.carrier_code) !== -1;
                });
                if(selectedAirlinesInOption.indexOf(false) !== -1){
                    return false;
                }
            }

            // if(index >= 10){
            //     return false;
            // }
            return true;
        });
    }
    return filtered;
}

let filterReducer = (filters={}, action) => {
    return {
        airlines:airlineFilterReducer(filters.airlines, action)
    }
};

let airlineFilterReducer = (airlines, action) =>{
    if(action.type === 'AIRLINE_SELECTED'){
        // console.log(action.airline_code);
        return [...airlines, action.airline_code];
    }
    if(action.type === 'AIRLINE_DESELECTED'){
        return airlines.filter(airline =>{
            return airline !== action.airline_code;
        });
    }
    return airlines;
}

let extractUniqueAirlines = items =>{
    let air_lines = [].concat(...items.map(item => {
        return item.journeys.map(journey =>{
            return journey.carrier_code;
        });
    })).filter((item, index, arr) =>{
        return arr.indexOf(item) === index;
    });
    // console.log(air_lines);
    return air_lines;
}

export default searchResultReducer;
