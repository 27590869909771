import React, { Component } from "react";

class FlightBreadCrumb extends Component {
  state = {};
  render() {
    return (
      <div className="page-head">
        <div className="page-title">
          Flights - <span>Alternative</span>
        </div>
        <div className="breadcrumbs">
          <a href="http://google.com">Home</a> /{" "}
          <span>Flight Alternative page</span>
        </div>
        <div className="clear" />
      </div>
    );
  }
}

export default FlightBreadCrumb;
