import React, { Component } from 'react';
import {connect} from "react-redux";
// import fetchAirline from "../../../stateManager/fetch/airline";
import fetchAirline from "./../../../../stateManager/fetch/airline";
import fetchCabin from '../../../../stateManager/fetch/cabin';


class AirlineBlock extends Component {
    state = {  }


  getAirline = (iata_code) => {
    let airline = {};

    try {
      airline = this.props.airlines[iata_code];
      if(airline === undefined){
        throw new Error('Airline not found in the state');
      }
    } catch (ex) {
      fetchAirline(iata_code);
      airline = this.props.empty_airline;
    }

    return airline;
  }


  getCabinType(cabin_type_code) {
    let cabin = {};

    try {
      cabin = this.props.cabins[cabin_type_code];
      if(cabin === undefined){
        throw new Error('CabinType not found in the state');
      }
    } catch (ex) {
      fetchCabin(cabin_type_code, this.props.dispatch);
      cabin = this.props.empty_cabin;
    }

    return cabin;
  }

    render() { 
        let segment = this.props.segment;
        return ( 
            <React.Fragment>
            <img
                          // style={{ width: "75px" }}
                          alt={segment.marketing.carrier_code}
                          // src="https://content.airhex.com/content/logos/airlines_9w_100_100_s.png"
                          // src={'https://content.airhex.com/content/logos/airlines_' + segment.marketing.carrier_code + '_30_20_s.png?proportions=keep'}
                          src={'http://pics.avs.io/30/20/' + segment.marketing.carrier_code + '@2x.png'}
                        /> <br />
                  {this.getAirline(segment.marketing.carrier_code).name} <br />
                  {segment.marketing.carrier_code}-{segment.marketing.number}{" "}
                  <br />
                  {this.getCabinType(segment.cabin_class).name}
                  {/* <div
              className="w3-col s12 w3-green"
              style={{ height: "50px", marginBottom: "10px" }}
            /> */}
            </React.Fragment>
         );
    }
}
 


let mapStateToProps = state => {
    return {
      airlines: state.airlines,
      empty_airline: state.empty_airline,
      cabins: state.cabins,
      empty_cabin: state.empty_cabin
    }
  }

export default connect(mapStateToProps)(AirlineBlock);