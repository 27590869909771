import React, { Component } from "react";
import FlightOption from "./flight-option";
import { connect } from "react-redux";

class FlightOptions extends Component {
  render() {
    if(this.props.error){
      return <div style={{color:"#ff7200"}}>Oops! something went wrong.</div>
    }
    if(!this.props.isLoaded){
      return (
        <div className="text-center text-rihlat">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div>Loading...</div>
        </div>
        )
    }
    if(this.props.isLoaded && this.props.items.length === 0){
      return <div style={{color: 'orange', marginBottom: '20px'}}>Oops! no result found for this segment</div>
    }

    return (
      <div className="catalog-row alternative">
        {/* <button className="btn btn-primary">hello</button>
        <button className="srch-btn">hello</button> */}
        {this.props.items.map((item, index) => (
            <FlightOption key={index} journeys={item.journeys} />
        ))}
      </div>
    );
  }
}

let mapStateToProp = state =>{
  return {
    // search_param: state.searches.latest,
    items: state.result.items.filtered,
    isLoaded: state.result.isLoaded,
    error: state.result.error
  }
}

export default connect(mapStateToProp)(FlightOptions);
