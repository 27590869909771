import React, { Component } from "react";
import Airlines from "./filters/Airlines";

class FlightsFilter extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        {/* <!-- // side // --> */}
        <div className="side-block fly-in">
          <div className="side-price">
            <div className="side-padding">
              <div className="side-lbl">Price</div>
              <div className="price-ranger">
                <div id="slider-range" />
              </div>
              <div className="price-ammounts">
                <input type="text" id="ammount-from" readOnly />
                <input type="text" id="ammount-to" readOnly />
                <div className="clear" />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- \\ side \\ --> */}

        {/* <!-- // side // --> */}
        <div className="side-block fly-in">
          <Airlines/>
        </div>
        {/* <!-- \\ side \\ -->
        <!-- // side // --> */}
        <div className="side-block fly-in">
          <div className="side-stars">
            <div className="side-padding">
              <div className="side-lbl">Flight type</div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  Business
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  First class
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  Economy
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- \\ side \\ -->
        <!-- // side // --> */}
        <div className="side-block fly-in">
          <div className="side-stars">
            <div className="side-padding">
              <div className="side-lbl">Flight times</div>

              <div className="side-time-holder">
                <div className="side-lbl-a">Departure flight</div>
                <div className="side-time">
                  <div className="time-ammounts">
                    departure time <span className="time-from">0</span>
                    :00 up to <span className="time-to">0</span>
                    :00
                  </div>
                  <div className="time-ranger">
                    <div className="time-range" />
                  </div>
                </div>
                <div className="side-time">
                  <div className="time-ammounts">
                    arrival time <span className="time-from">0</span>
                    :00 up to <span className="time-to">0</span>
                    :00
                  </div>
                  <div className="time-ranger">
                    <div className="time-range" />
                  </div>
                </div>
              </div>
              <div className="side-time-holder">
                <div className="side-lbl-a">return flight</div>
                <div className="side-time">
                  <div className="time-ammounts">
                    departure time <span className="time-from">0</span>
                    :00 up to <span className="time-to">0</span>
                    :00
                  </div>
                  <div className="time-ranger">
                    <div className="time-range" />
                  </div>
                </div>
                <div className="side-time">
                  <div className="time-ammounts">
                    arrival time <span className="time-from">0</span>
                    :00 up to <span className="time-to">0</span>
                    :00
                  </div>
                  <div className="time-ranger">
                    <div className="time-range" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- \\ side \\ --> */}
      </React.Fragment>
    );
  }
}

export default FlightsFilter;
