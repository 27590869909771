import React, { Component } from "react";
import LayoutMain from "./../layout/layout-main";

class PageNotFound extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <LayoutMain>
          <div style={{ position: "relative", padding: "130px 0px 0px 0px" }}>
            <h1 style={{ textAlign: "center" }}>404</h1>
            <h3 style={{ textAlign: "center" }}>Page Not Found!</h3>
          </div>
        </LayoutMain>
      </React.Fragment>
    );
  }
}

export default PageNotFound;
