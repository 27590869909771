import React, { Component } from "react";

import Header from "./header";
import FooterA from "./footers/footer-a";
import FooterB from "./footers/footer-b";

class LayoutMain extends Component {
  render() {
    // console.log(this.props.children);
    return (
      <React.Fragment>
        {/* index 3 */}
        <Header />
        <div className="main-cont">{this.props.children}</div>
        <FooterA />
        <FooterB />
      </React.Fragment>
    );
  }
}

export default LayoutMain;
