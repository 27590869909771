import React, { Component } from "react";

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const selectOptions = this.props.options.map(option => (
      <option value={option.key} key={option.key}>
        {option.value}
      </option>
    ));
    let selectClassName = this.props.class || "";
    let selectName = this.props.name || "";
    return (
      <select
        name={selectName}
        value={this.props.value}
        className={selectClassName}
        onChange={this.props.onChange}
      >
        {selectOptions}
      </select>
    );
  }
}

export default Select;
