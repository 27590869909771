
const defaultAirlines = {};

let airlinesReducer = (airlines =defaultAirlines, action) => {
    if(action.type === 'UPDATE_AIRLINE'){
        localStorage.setItem(action.iata_code, JSON.stringify(action.data));
        return Object.assign({}, airlines, {
            [action.iata_code]:action.data
        });
    }
    return airlines;
};

const defaultEmptyAirline = {
    name: 'Airline name',
    iata_code: 'N/A',
    iata_account_code: 'N/A'
};

export let emptyAirline = (state = defaultEmptyAirline, action) =>{
    return state;
}


export default airlinesReducer;
