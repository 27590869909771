import React, { Component } from "react";

class FlightsSorting extends Component {
  state = {};
  render() {
    return (
      <div className="catalog-head fly-in">
        <label>Sort results by:</label>
        <div className="search-select">
          <select>
            <option>Name</option>
            <option>Name</option>
            <option>Name</option>
            <option>Name</option>
            <option>Name</option>
          </select>
        </div>
        <div className="search-select">
          <select>
            <option>Price</option>
            <option>Price</option>
            <option>Price</option>
            <option>Price</option>
            <option>Price</option>
          </select>
        </div>
        <div className="search-select">
          <select>
            <option>Duration</option>
            <option>Duration</option>
            <option>Duration</option>
            <option>Duration</option>
            <option>Duration</option>
          </select>
        </div>
        <a href="http://google.com" className="show-list"  >&nbsp;</a>
        <a className="show-thumbs chosen" href="http://google.com" >&nbsp;</a>
        <div className="clear" />
      </div>
    );
  }
}

export default FlightsSorting;
