import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "../../../form-elements/select";
// import store from './../../../../stateManager/store';
import { connect } from 'react-redux';

class Flight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flight_search: {
        source: "DEL",
        destin: "BOM",
        depart: moment(),
        return: moment(),
        adult: 1,
        child: 0,
        infant: 0,
        class: "e"
      }
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    // this.handleReturnChange = this.handleReturnChange.bind(this);
    // this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleDateChange(date, name) {
    let action = { type: 'SEARCH_PARAM_CHANGED', param: { [name]: date } };
    this.props.dispatch(action)
    // let flightSearch = this.state.flight_search;
    // flightSearch.depart = date;
    // // flightSearch['depart'] = date
    // this.setState({
    //   flight_search:flightSearch
    // });
  }

  // handleReturnChange(date) {
  //   // console.log(date);
  //   let flightSearch = this.state.flight_search;
  //   flightSearch.return = date;
  //   // flightSearch['return'] = date;
  //   this.setState({
  //     flight_search:flightSearch
  //   });
  // }
  // handleInputChange(event) {
  //   const target = event.target;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const name = target.name;
  //
  //   let flightSearch = this.props.flight_search;
  //   flightSearch[name] = value;
  //   this.setState({
  //     flight_search:flightSearch
  //   });
  // }

  handleSubmit(event) {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  }

  searchFlight() {
    let flight_search = this.props.flight_search;
    this.props.dispatch({"type":'NEW_SERCH'});
    let search_filter = {
      source: flight_search.source,
      destin: flight_search.destin,
      depart: flight_search.depart.format("YYYY-MM-DD"),
      adult: flight_search.adult,
      child: flight_search.child,
      infant: flight_search.infant,
      class: flight_search.class,
      return: flight_search.return.format("YYYY-MM-DD"),
    };

    let search_val_as_arr = Object.values(search_filter);
    let url = search_val_as_arr.join('-');
    // let url =
    //   flight_search.source +
    //   "-" +
    //   flight_search.destin +
    //   "-" +
    //   flight_search.depart.format("YYYY-MM-DD") +
    //   "-" +
    //   flight_search.adult +
    //   "-" +
    //   flight_search.child +
    //   "-" +
    //   flight_search.infant +
    //   "-" +
    //   flight_search.class +
    //   "-" +
    //   flight_search.return.format("YYYY-MM-DD");
    window.location = "/flights/" + url;
  }

  render() {
    let passengerOptions = [
      { key: 0, value: 0 },
      { key: 1, value: 1 },
      { key: 2, value: 2 },
      { key: 3, value: 3 },
      { key: 4, value: 4 },
      { key: 5, value: 5 },
      { key: 6, value: 6 },
      { key: 7, value: 7 },
      { key: 8, value: 8 },
      { key: 9, value: 9 }
    ];
    return (
      <React.Fragment>
        {/* <div className="search-tab-content"> */}
        <div className="page-search-p">
          <div className="search-large-i">
            <div className="srch-tab-left">
              <div className="custom-control custom-radio">
                <input className="custom-control-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                <label className="custom-control-label" for="inlineRadio1">ONEWAY</label>
              </div>
            </div>
            <div className="srch-tab-left">
              <div className="custom-control custom-radio">
                <input className="custom-control-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                <label className="custom-control-label" for="inlineRadio2">ROUNDTRIP</label>
              </div>
            </div>
          </div>
        </div>
        <div className="page-search-p">
          <div className="search-large-i">
            <div className="srch-tab-line no-margin-bottom">
              <div className="srch-tab-left">
                <label>From</label>
                <div className="input-a">
                  <input
                    name="source"
                    type="text"
                    value={this.props.flight_search.source}
                    placeholder="Austria, vienna"
                    onChange={this.props.handleInputChange}
                  />
                </div>
              </div>
              <div className="srch-tab-right">
                <label>to</label>
                <div className="input-a">
                  <input
                    name="destin"
                    type="text"
                    value={this.props.flight_search.destin}
                    placeholder="--"
                    onChange={this.props.handleInputChange}
                  />
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="search-large-i">
            <div className="srch-tab-line no-margin-bottom">
              <div className="srch-tab-left">
                <label>Departure</label>
                <div className="input-a">
                  <DatePicker
                    selected={this.props.flight_search.depart}
                    name="depart"
                    className="date-inpt"
                    placeholderText="yyyy-mm-dd"
                    dateFormat="YYYY-MM-DD"
                    onChange={ (date) => this.handleDateChange(date, 'depart')}
                  />
                  <span className="date-icon" />
                </div>
              </div>
              <div className="srch-tab-right">
                <label>Return</label>
                <div className="input-a">
                  <DatePicker
                    selected={this.props.flight_search.return}
                    name="return"
                    className="date-inpt"
                    placeholderText="yyyy-mm-dd"
                    dateFormat="YYYY-MM-DD"
                    onChange={ (date) => this.handleDateChange(date, 'return')}
                  />
                  <span className="date-icon" />
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="clear" style={{ marginBottom: 20 }} />
          <div className="search-large-i">
            <div className="srch-tab-line no-margin-bottom">
              <div className="srch-tab-left transformed">
                <label>Adult</label>
                <div className="select-wrapper">
                  <Select
                    options={passengerOptions}
                    value={this.props.flight_search.adult}
                    name="adult"
                    onChange={this.props.handleInputChange}
                    class="custom-select"
                  />
                </div>
              </div>
              <div className="srch-tab-right transformed">
                <label>Child</label>
                <div className="select-wrapper">
                  <Select
                    options={passengerOptions}
                    value={this.props.flight_search.child}
                    name="child"
                    onChange={this.props.handleInputChange}
                    class="custom-select"
                  />
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="search-large-i">
            <div className="srch-tab-line no-margin-bottom">
              <div className="srch-tab-left transformed">
                <label>Infant</label>
                <div className="select-wrapper">
                  <Select
                    options={passengerOptions}
                    value={this.props.flight_search.infant}
                    name="infant"
                    onChange={this.props.handleInputChange}
                    class="custom-select"
                  />
                </div>
              </div>
              <div className="srch-tab-right transformed">
                <label>Class</label>
                <div className="select-wrapper">
                  <Select
                    options={[
                      { key: "e", value: "Economy" },
                      { key: "b", value: "Business" },
                      { key: "f", value: "First" }
                    ]}
                    value={this.props.flight_search.class}
                    name="class"
                    onChange={this.props.handleInputChange}
                    class="custom-select"
                  />
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="clear" />
          <div className="search-asvanced">
            <div className="search-large-i">
              <div className="srch-tab-line no-margin-bottom">
                <label>Price</label>
                <div className="select-wrapper">
                  <select className="custom-select">
                    <option>--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="search-large-i">
              <div className="srch-tab-line no-margin-bottom">
                <label>Air company</label>
                <div className="select-wrapper">
                  <select className="custom-select">
                    <option>--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="search-large-i">
              <div className="srch-tab-line no-margin-bottom">
                <label>Rating</label>
                <div className="select-wrapper">
                  <select className="custom-select">
                    <option>--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <footer className="search-footer">
          <button className="srch-btn" onClick={() => this.searchFlight()}>
            Search
          </button>
          <span className="srch-lbl">Advanced Search options</span>
          <div className="clear" />
        </footer>
        {/* </div> */}
      </React.Fragment>
    );
  }
}



const mapStateToProps = (state /*, ownProps*/) => {
  return {
    flight_search: state.searches.latest
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleInputChange: event => {
      let target = event.target;
      let value = target.type === "checkbox" ? target.checked : target.value;
      let name = target.name;
      let action = { type: 'SEARCH_PARAM_CHANGED', param: { [name]: value } };
      dispatch(action)
    },
    dispatch
  }
}

// export default Flight;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Flight)
