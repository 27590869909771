
const defaultCabins = {};

let cabinTypes = (cabins = defaultCabins, action) => {
    if(action.type === 'UPDATE_CABIN'){
        // let new_cabins = Object.assign({}, cabins, {
        //     [action.code]:action.data
        // });
        let new_cabins = {...cabins, 
            [action.code]:action.data
        };
        localStorage.setItem('cabinTypes', JSON.stringify(new_cabins));
        return new_cabins;
    }
    return cabins;
}

const defaultEmptyCabin = {
    code: 'cabinCode',
    name: 'cabinTypeName'
};

export let emptyCabin = (cabin = defaultEmptyCabin, action) => {
    return cabin;
}

export default cabinTypes;