import React, { Component } from 'react';
import { connect } from 'react-redux';
import fetchAirline from '../../../../stateManager/fetch/airline';

class Airlines extends Component {
    getAirline = (iata_code) => {
      let airline = {};
  
      try {
        airline = this.props.airlines[iata_code];
        if(airline === undefined){
          throw new Error('Airline not found in the state');
        }
      } catch (ex) {
        fetchAirline(iata_code);
        airline = this.props.empty_airline;
      }
  
      return airline;
    }

    handleAirlineCheckUncheck = event =>{
        console.log(event);
        console.log(event.target.checked);
        console.log(event.target.value, event.target.name);
        if(event.target.checked){
            this.props.dispatch({type: 'AIRLINE_SELECTED', airline_code: event.target.name});
        }else{
            this.props.dispatch({type: 'AIRLINE_DESELECTED', airline_code: event.target.name});
        }
        setTimeout(
          () => this.props.dispatch({type: 'APPLY_SEARCH_FILTER',  data: this.props.all_items, filters: this.props.filters}),
          700
        );
        
    }

    render() { 
        let airlinesList = this.props.airlines_code.map(airline_code => {
            return (
                <div className="checkbox" key={airline_code}>
                    <label>
                        <input type="checkbox" name={airline_code} value='1' onClick={this.handleAirlineCheckUncheck} />
                        {this.getAirline(airline_code).name} ({airline_code})
                    </label>
                </div>
            );
        });

        return ( 
            <div className="side-stars">
            <div className="side-padding">
              <div className="side-lbl">Airlines</div>
              {airlinesList}
              {/* <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  Lufthansa (30)
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  United Airlines (18)
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  Air berlin (8)
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  Swiss (2)
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  Turkish Airlines (1)
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  Air france (1)
                </label>
              </div> */}
            </div>
          </div>
         );
    }
}
 
let mapStateToProps = state => {
    return {
        airlines_code: state.result.filter_options.airlines,
        airlines: state.airlines,
        empty_airline: state.empty_airline,
        all_items: state.result.items.all,
        filters: state.result.filters
    }
}

export default connect(mapStateToProps)(Airlines);