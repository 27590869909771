// import { type } from "os";

let fetchCabin = (cabin_type_code, dispatch) => {
    //   let in_storage = localStorage.getItem(cabin_type_code);
    //   if(in_storage != null){
    //         return dispatch({ type: 'UPDATE_CABIN', code: cabin_type_code, data: JSON.parse(in_storage) });
    //   }
    let url = process.env.REACT_APP_API_BASE_URL + '/cabintype/' + cabin_type_code;
    if(localStorage.getItem('in_process'+url) !== null){
        return false;
    }
    localStorage.setItem('in_process'+url, 'running');
    fetch(url)
        .then(response => response.json())
        .then(json => {
            if(json.code === 200){
                // console.log(typeof(json.code));
                dispatch({ type: 'UPDATE_CABIN', code: cabin_type_code, data: json.data });
                localStorage.removeItem('in_process'+url);
            }
        }, error => console.log(error));
}

export default fetchCabin;
