import React, { Component } from "react";
import LayoutMain from "../layout/layout-main";
import Slider from "./_main-page/slider";
import SearchTabs from "./_main-page/search-tabs";

class MainPage extends Component {
  state = {};
  render() {
    return (
      <LayoutMain>
        <Slider />
        <SearchTabs />
      </LayoutMain>
    );
  }
}

export default MainPage;
