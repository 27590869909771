import appReducer from "./app-reducer"
import {createStore, applyMiddleware, compose} from "redux";
import initialState from "./initialState";
import logger from 'redux-logger';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleWares = [logger];

let store = createStore(
    appReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleWares)),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

export let dispatch = store.dispatch;
export let state = store.getState();

export default store;
