// import moment from "moment";

// function getFromStorage(){
//     if(localStorage.getItem('searches') != null){
//         let searches = JSON.parse(localStorage.getItem('searches'));
//         return Object.assign({}, searches, {
//             latest: Object.assign(searches.latest, {
//                 depart: moment(searches.latest.depart),
//                 return: moment(searches.latest.return)
//             })
//         })
//     }
//     return {
//         recent: [],
//         latest: {
//             source: "DEL",
//             destin: "BOM",
//             depart: moment().add(5, 'days'),
//             return: moment().add(12, 'days'),
//             adult: 1,
//             child: 0,
//             infant: 0,
//             class: "e"
//         }
//     };
// }
const initialState = {
    // searches: getFromStorage(),
    // result: {
    //   error: false,
    //   isLoaded: false,
    //   filter_options: {
    //       airlines: []
    //   },
    //   filters: {
    //       airlines: []
    //   },
    //   items: {
    //       all: [],
    //       filtered: []
    //   }
    // },
    // airports: {},
    // empty_airport:{
    //     name: 'Airport name',
    //     city_name: 'City',
    //     code: 'N/A'
    // },
    // airlines: {},
    // empty_airline:{
    //     name: 'Airline name',
    //     iata_code: 'N/A',
    //     iata_account_code: 'N/A'
    // },
    // cabins:{},
    // empty_cabin:{
    //     code: 'cabinCode',
    //     name: 'cabinTypeName'
    // }
};

export default initialState;
