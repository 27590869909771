import React, { Component } from "react";
import LayoutMain from "../layout/layout-main";
import FlightBreadCrumb from "./_flights/bread-crumb";
import FlightsFilter from "./_flights/filters";
import FlightsSorting from "./_flights/sorting";
import FlightOptions from "./_flights/flight-options";
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";

class Flights extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputs: {
        from: '',
        to: '',
        depart_date: '',
        adults: '',
        children: '',
        infants: '',
        cabin_class: '',
        return_date: ''
      },
      error: false,
      isLoaded: false,
      items: []
    };
  }
  parseSearchParam(search_param) {
    // console.log(search_param);
    // var myRe1 = new RegExp('(\w{3})-(\w{3})-(\d{4}-\d{2}-\d{2})-(\d{1})-(\d{1})-(\d{1})-(\d{1})-(\d{4}-\d{2}-\d{2})', 'g');
    var re = new RegExp(
      "([A-Z]{3})-([A-Z]{3})-([0-9]{4}-[0-9]{2}-[0-9]{2})-([0-9]{1})-([0-9]{1})-([0-9]{1})-([a-z]{1})-([0-9]{4}-[0-9]{2}-[0-9]{2})",
      "g"
    );
    var matched = re.exec(search_param);
    // console.log(matched);
    if (matched !== null) {
      var params = matched.slice(1);
      // console.log(params);
      return {
        from: params[0],
        to: params[1],
        depart_date: params[2],
        adults: params[3],
        children: params[4],
        infants: params[5],
        cabin_class: params[6],
        return_date: params[7]
      };
    }
    return matched;
  }
  componentDidMount() {
    // console.log(this.parseSearchParam(this.props.search));
    var input = this.parseSearchParam(this.props.search);
    this.setState({
      inputs: input
    });
    // console.log(input);
    var url =
      process.env.REACT_APP_API_BASE_URL + "/availability/" +
      input.from +
      "/" +
      input.to +
      "/" +
      input.adults +
      "/" +
      input.children +
      "/" +
      input.infants +
      "/" +
      input.cabin_class +
      "/" +
      input.depart_date +
      "/" +
      input.return_date +
      "/";
    fetch(url)
      .then(res => res.json())
      .then(
        result => {
          console.log(result.data.options);
          // this.setState({
          //   isLoaded: true,
          //   items: result.data.options
          // });
          this.props.dispatch({type: 'UPDATE_SEARCH_RESULT', data: result.data.options});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {
          // this.setState({
          //   isLoaded: true,
          //   error:true
          // });
          this.props.dispatch({type: 'SEARCH_RESULT_FAILED'});
        }
      );
  }
  render() {
    // const { inputs, error, isLoaded, items } = this.state;
    const { items } = this.props;
    const paxes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const paxItems = paxes.map(pax => <option key={pax} value={pax}>{pax}</option>);
    return (
      <LayoutMain>
        <div className="body-wrapper">
          <div className="wrapper-padding">
            <FlightBreadCrumb />
            <div className="two-colls">
              <div className="two-colls-left">
                <div className="srch-results-lbl fly-in">
                  <span style={{ color: items.length === 0 ? "red" : "green" }}>
                    {items.length} results found.
                  </span>
                </div>

                <div className="side-block fly-in">
                  <div className="side-block-search">
                    <div className="page-search-p">
                      {/* <!-- // --> */}
                      <div className="srch-tab-line">
                        <div className="srch-tab-left">
                          <label>From</label>
                          <div className="input-a">
                            <input
                              type="text"
                              placeholder="Source"
                              defaultValue={this.props.search_param.source}
                            />
                          </div>
                        </div>
                        <div className="srch-tab-right">
                          <label>To</label>
                          <div className="input-a">
                            <input
                              type="text"
                              placeholder="Destination"
                              defaultValue={this.props.search_param.destin}
                            />
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                      <div className="srch-tab-line">
                        <div className="srch-tab-left">
                          <label>Departure</label>
                          <div className="input-a">
                            <DatePicker
                              selected={this.props.search_param.depart}
                              name="depart"
                              className="date-inpt"
                              placeholderText="yyyy-mm-dd"
                              dateFormat="YYYY-MM-DD"
                              // onChange={this.handleDepartChange}
                            />
                            <span className="date-icon" />
                          </div>
                        </div>
                        <div className="srch-tab-right">
                          <label>Return</label>
                          <div className="input-a">
                            <DatePicker
                              selected={this.props.search_param.return}
                              name="return"
                              className="date-inpt"
                              placeholderText="yyyy-mm-dd"
                              dateFormat="YYYY-MM-DD"
                              // onChange={this.handleReturnChange}
                            />
                            <span className="date-icon" />
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                      <div className="srch-tab-line">
                        <div className="srch-tab-left transformed">
                          <label>Adults</label>
                          <div className="select-wrapper">
                            <select className="custom-select" defaultValue={this.props.search_param.adult}>
                              {paxItems}
                            </select>
                          </div>
                        </div>
                        <div className="srch-tab-right transformed">
                          <label>Children</label>
                          <div className="select-wrapper">
                            <select className="custom-select" defaultValue={this.props.search_param.child}>
                              {paxItems}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="srch-tab-line no-margin-bottom">
                        <div className="srch-tab-left transformed">
                          <label>Infants</label>
                          <div className="select-wrapper">
                            <select className="custom-select" defaultValue={this.props.search_param.infant}>
                              {paxItems}
                            </select>
                          </div>
                        </div>
                        <div className="srch-tab-right transformed">
                          <label>Class</label>
                          <div className="select-wrapper">
                            <select
                              className="custom-select"
                              defaultValue={this.props.search_param.class}
                            >
                              <option>--</option>
                              <option value="e">Economy</option>
                              <option value="p">Premium Economy</option>
                              <option value="b">Business</option>
                              <option value="f">First</option>
                            </select>
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                      {/* <!-- \\ -->	 */}

                      <button className="srch-btn">Search</button>
                    </div>
                  </div>
                </div>

                <FlightsFilter />
              </div>
              <div className="two-colls-right">
                <div className="two-colls-right-b">
                  <div className="padding">
                    <FlightsSorting />
                    <FlightOptions
                      // items={this.props.items}
                      // isLoaded={this.props.isLoaded}
                      // error={this.props.error}
                    />
                    <div className="clear" />

                    <div className="pagination">
                      <a className="active" href="http://google.com">
                        1
                      </a>
                      <a href="http://google.com">2</a>
                      <a href="http://google.com">3</a>
                      <div className="clear" />
                    </div>
                  </div>
                </div>
                <br className="clear" />
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
      </LayoutMain>
    );
  }
}

let mapStateToProp = state =>{
  return {
    search_param: state.searches.latest,
    items: state.result.items.filtered
    // isLoaded: state.result.isLoaded,
    // error: state.result.error
  }
}

export default connect(mapStateToProp)(Flights)
