import React, { Component } from "react";
import HeaderA from "./headers/header-a";
import HeaderB from "./headers/header-b"

class Header extends Component {
  render() {
    return (
      <header id="top">
        <HeaderA />
        <HeaderB />
      </header>
    );
  }
}

export default Header;
