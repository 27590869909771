import React, { Component } from "react";

class FooterB extends Component {
  state = {};
  render() {
    return (
      <footer className="footer-b">
        <div className="wrapper-padding">
          <div className="footer-left">
            © Copyright 2015 by Weblionmedia. All rights reserved.
          </div>
          <div className="footer-social">
            <a href="http://google.com" className="footer-twitter" >&nbsp;</a>
            <a href="http://google.com" className="footer-facebook" >&nbsp;</a>
            <a href="http://google.com" className="footer-vimeo" >&nbsp;</a>
            <a href="http://google.com" className="footer-pinterest" >&nbsp;</a>
            <a href="http://google.com" className="footer-instagram" >&nbsp;</a>
          </div>
          <div className="clear" />
        </div>
      </footer>
    );
  }
}

export default FooterB;
