import React, { Component } from "react";
import Hotel from "./search-forms/hotel";
import Tour from "./search-forms/tour";
import Flight from "./search-forms/flight";
import SearchTab from "./search-tab";

class SearchTabs extends Component {
  state = {};
  items = [
    { key: "Hotels", tabItem: Hotel },
    { key: "Tours", tabItem: Tour },
    { key: "Flights", tabItem: Flight }
  ];

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 2
    };
  }
  tabClicked(clickedIndex) {
    this.setState(state => ({
      activeTab: clickedIndex
    }));
  }
  render() {
    let tabList = this.items.map((item, index) => {
      let tabClass = "search-tab";
      tabClass += index === this.state.activeTab ? " active" : " ";
      return (
        <div
          key={item.key}
          className={tabClass}
          onClick={e => this.tabClicked(index)}
        >
          {item.key}
        </div>
      );
    });
    let tabContents = this.items.map((item, index) => {
      let active = index === this.state.activeTab ? true : false;
      return <SearchTab key={index} content={item} active={active} />;
    });

    return (
      <React.Fragment>
        <div className="wrapper-a-holder full-width-search">
          <div className="wrapper-a">
            <div className="page-search full-width-search search-type-b">
              <div className="search-type-padding">
                <nav className="page-search-tabs">
                  {tabList}
                  <div className="clear" />
                </nav>
                <div className="page-search-content">{tabContents}</div>
              </div>
            </div>

            <div className="clear" />
          </div>
        </div>
        <div className="mp-pop">
          <div className="wrapper-padding-a" />
        </div>
      </React.Fragment>
    );
  }
}

export default SearchTabs;
