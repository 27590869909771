import React, { Component } from "react";

class HeaderB extends Component {
  state = {
    scrollTop: 0
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  handleScroll = event => {
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    // this.state.scrollTop = scrollTop;
    // console.log(this.state.scrollTop);
    this.setState({
      scrollTop: scrollTop
    });
  };
  render() {
    let header_b_classes = "header-b ";
    header_b_classes += this.state.scrollTop > 140 ? "fixed" : "";
    let header_b_css =
      this.state.scrollTop > 140 ? { height: "59px" } : { height: "89px" };
    let header_right_css = {
      "marginTop": this.state.scrollTop > 140 ? "21px" : "37px"
    };
    // : { "margin-top" };
    return (
      <div className={header_b_classes} style={header_b_css}>
        <div className="mobile-menu">
          <nav>
            <ul>
              <li>
                <a className="has-child" href="http://google.com">
                  HOME
                </a>
                <ul>
                  <li>
                    <a href="index.html">Home style one</a>
                  </li>
                  <li>
                    <a href="index_02.html">Home style two</a>
                  </li>
                  <li>
                    <a href="index_03.html">Home style three</a>
                  </li>
                  <li>
                    <a href="index_04.html">Home style four</a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="has-child" href="http://google.com">
                  Hotels
                </a>
                <ul>
                  <li>
                    <a href="hotel_list.html">Hotels standard list</a>
                  </li>
                  <li>
                    <a href="hotel_simple_style.html">Hotels simple style</a>
                  </li>
                  <li>
                    <a href="hotel_detail_style.html">Hotels detail style</a>
                  </li>
                  <li>
                    <a href="hotel_detail.html">Hotel item page</a>
                  </li>
                  <li>
                    <a href="hotel_booking.html">Hotel booking page</a>
                  </li>
                  <li>
                    <a href="http://google.com">booking complete page</a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="has-child" href="http://google.com">
                  Flights
                </a>
                <ul>
                  <li>
                    <a href="flight_round_trip.html">Flights round trip</a>
                  </li>
                  <li>
                    <a href="flight_one_way.html">flights one way trip</a>
                  </li>
                  <li>
                    <a href="flight_alternative.html">
                      flights alternative style
                    </a>
                  </li>
                  <li>
                    <a href="flight_detail.html">Flights detail page</a>
                  </li>
                  <li>
                    <a href="flight_booking.html">Flights booking page</a>
                  </li>
                  <li>
                    <a href="booking_complete.html">booking complete</a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="has-child" href="http://google.com">
                  Tours
                </a>
                <ul>
                  <li>
                    <a href="tour_alternative.html">Tours list style</a>
                  </li>
                  <li>
                    <a href="tour_grid.html">tours grid style</a>
                  </li>
                  <li>
                    <a href="tour_simple.html">Tours simple style</a>
                  </li>
                  <li>
                    <a href="tour_detail.html">Tour detail page</a>
                  </li>
                  <li>
                    <a href="tour_booking.html">tour booking page</a>
                  </li>
                  <li>
                    <a href="booking_complete.html">booking complete</a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="has-child" href="http://google.com">
                  Pages
                </a>
                <ul>
                  <li>
                    <a href="about_us.html">about us style one</a>
                  </li>

                  <li>
                    <a href="services.html">services</a>
                  </li>
                  <li>
                    <a href="contacts.html">contact us</a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="has-child" href="http://google.com">
                  Portfolio
                </a>
                <ul>
                  <li>
                    <a href="portfolio_three_collumns.html">
                      Portfolio three columns
                    </a>
                  </li>
                  <li>
                    <a href="portfolio_four_collumns.html">
                      portfolio four columns
                    </a>
                  </li>
                  <li>
                    <a href="item_page.html">item page</a>
                  </li>
                  <li>
                    <a href="item_page_full_width.html">
                      Item page full width style
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="has-child" href="http://google.com">
                  Blog
                </a>
                <ul>
                  <li>
                    <a href="blog_with_sidebar.html">Blog with sidebar</a>
                  </li>
                  <li>
                    <a href="blog_masonry.html">blog masonry style</a>
                  </li>
                  <li>
                    <a href="standart_blog_post.html">Blog post example</a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="has-child" href="http://google.com">
                  Features
                </a>
                <ul>
                  <li>
                    <a href="typography.html">typography</a>
                  </li>
                  <li>
                    <a href="shortcodes.html">shortcodes</a>
                  </li>
                  <li>
                    <a href="interactive_elements.html">interactive elements</a>
                  </li>
                  <li>
                    <a href="cover_galleries.html">cover galleries</a>
                  </li>
                  <li>
                    <a href="columns.html">columns</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="contacts.html">CONTACS</a>
              </li>
            </ul>
          </nav>
        </div>

        <div className="wrapper-padding">
          <div className="header-logo">
            <a href="/">
              <img alt="" src="./../../../img/logo.png" />
            </a>
          </div>
          <div className="header-right" style={header_right_css}>
            <div className="hdr-srch">
              <a href="http://google.com" className="hdr-srch-btn" >&nbsp;</a>
            </div>
            <div className="hdr-srch-overlay">
              <div className="hdr-srch-overlay-a">
                <input type="text" placeholder="Start typing..." />
                <a href="http://google.com" className="srch-close" >&nbsp;</a>
                <div className="clear" />
              </div>
            </div>
            <div className="hdr-srch-devider" />
            <a href="http://google.com" className="menu-btn" >&nbsp;</a>
            <nav className="header-nav">
              <ul>
                <li>
                  <a href="/">Home</a>
                  <ul>
                    <li>
                      <a href="index.html">Home style one</a>
                    </li>
                    <li>
                      <a href="index_02.html">Home style two</a>
                    </li>
                    <li>
                      <a href="index_03.html">Home style three</a>
                    </li>
                    <li>
                      <a href="index_04.html">Home style four</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="http://google.com">Hotels</a>
                  <ul>
                    <li>
                      <a href="hotel_list.html">Hotels standard list</a>
                    </li>
                    <li>
                      <a href="hotel_simple_style.html">Hotels simple style</a>
                    </li>
                    <li>
                      <a href="hotel_detail_style.html">Hotels detail style</a>
                    </li>
                    <li>
                      <a href="hotel_detail.html">Hotel item page</a>
                    </li>
                    <li>
                      <a href="hotel_booking.html">Hotel booking page</a>
                    </li>
                    <li>
                      <a href="booking_complete.html">booking complete page</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="http://google.com">Flights</a>
                  <ul>
                    <li>
                      <a href="flight_round_trip.html">Flights round trip</a>
                    </li>
                    <li>
                      <a href="flight_one_way.html">flights one way trip</a>
                    </li>
                    <li>
                      <a href="flight_alternative.html">
                        flights alternative style
                      </a>
                    </li>
                    <li>
                      <a href="flight_detail.html">Flights detail page</a>
                    </li>
                    <li>
                      <a href="flight_booking.html">Flights booking page</a>
                    </li>
                    <li>
                      <a href="booking_complete.html">booking complete</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="http://google.com">Tours</a>
                  <ul>
                    <li>
                      <a href="tour_alternative.html">Tours list style</a>
                    </li>
                    <li>
                      <a href="tour_grid.html">tours grid style</a>
                    </li>
                    <li>
                      <a href="tour_simple.html">Tours simple style</a>
                    </li>
                    <li>
                      <a href="tour_detail.html">Tour detail page</a>
                    </li>
                    <li>
                      <a href="tour_booking.html">tour booking page</a>
                    </li>
                    <li>
                      <a href="booking_complete.html">booking complete</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="http://google.com">Pages</a>
                  <ul>
                    <li>
                      <a href="about_us.html">about us style one</a>
                    </li>

                    <li>
                      <a href="services.html">services</a>
                    </li>
                    <li>
                      <a href="contacts.html">contact us</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="http://google.com">Portfolio</a>
                  <ul>
                    <li>
                      <a href="portfolio_three_collumns.html">
                        Portfolio three columns
                      </a>
                    </li>
                    <li>
                      <a href="portfolio_four_collumns.html">
                        portfolio four columns
                      </a>
                    </li>
                    <li>
                      <a href="item_page.html">item page</a>
                    </li>
                    <li>
                      <a href="item_page_full_width.html">
                        Item page full width style
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="http://google.com">Blog</a>
                  <ul>
                    <li>
                      <a href="blog_with_sidebar.html">Blog with sidebar</a>
                    </li>
                    <li>
                      <a href="blog_masonry.html">blog masonry style</a>
                    </li>
                    <li>
                      <a href="standart_blog_post.html">Blog post example</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="http://google.com">Features</a>
                  <ul>
                    <li>
                      <a href="typography.html">typography</a>
                    </li>
                    <li>
                      <a href="shortcodes.html">shortcodes</a>
                    </li>
                    <li>
                      <a href="interactive_elements.html">
                        interactive elements
                      </a>
                    </li>
                    <li>
                      <a href="cover_galleries.html">cover galleries</a>
                    </li>
                    <li>
                      <a href="columns.html">columns</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="contacts.html">Contacts</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default HeaderB;
