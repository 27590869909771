import React, { Component } from "react";

class Slider extends Component {
  render() {
    let img_style = { background: "url('img/sider-03.jpg') center" };
    return (
      <div className="mp-slider search-only">
        <div className="mp-slider-row slim-slider">
          <div className="swiper-container" style={img_style}>
            {/* <img src="img/sider-03.jpg" /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Slider;
