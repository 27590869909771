import React, { Component } from "react";
import Tabs, { Tab } from "./../../tabs/tabs";
import Segments from "./segments";

class FlightOption extends Component {
  state = {
    detail: false
  };
  toggleDetail() {
    this.setState(state => ({
      detail: !this.state.detail
    }));
  }
  render() {
    // console.log(this.props.journeys[0].segments);
    var total_segment = this.props.journeys[0].segments.length;
    var first_segment = this.props.journeys[0].segments[0];
    var last_segment = this.props.journeys[0].segments[total_segment - 1];
    // console.log(total_segment);
    return (
      <div className="alt-flight fly-in">
        <div className="alt-flight-a">
          <div className="alt-flight-l">
            <div className="alt-flight-lb">
              <div className="alt-center">
                <div className="alt-center-l">
                  <div className="alt-center-lp">
                    <div className="alt-logo">
                      <a href="http://google.com">
                        {/* <img alt="" src="img/fl-transp-01.png" /> */}
                        <img
                          style={{ width: "75px" }}
                          alt=""
                          // src="https://content.airhex.com/content/logos/airlines_9w_100_100_s.png"
                          // src={'https://content.airhex.com/content/logos/airlines_' + first_segment.marketing.carrier_code + '_100_100_s.png'}
                          src={'http://pics.avs.io/100/100/' + first_segment.marketing.carrier_code + '@2x.png'}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="alt-center-c">
                  <div className="alt-center-cb">
                    <div className="alt-center-cp">
                      <div className="alt-lbl">
                        {first_segment.departure.code}-
                        {last_segment.arrival.code}
                      </div>
                      <div className="alt-info">
                        <b>{this.props.journeys[0].departure_date}</b> One way
                        trip
                      </div>
                      <div className="alt-devider" />
                      <div
                        className="flight-line-b"
                        onClick={e => this.toggleDetail()}
                      >
                        <b>details</b>
                        <span>Only 2 seats!</span>
                      </div>
                      <div className="alt-data-i alt-departure">
                        <b>Departure</b>
                        <span>{this.props.journeys[0].departure_time}</span>
                      </div>
                      <div className="alt-data-i alt-arrival">
                        <b>Arrival</b>
                        <span>{this.props.journeys[0].arrival_time}</span>
                      </div>
                      <div className="alt-data-i alt-time">
                        <b>time</b>
                        <span>14H 50M</span>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              </div>
              <div className="clear" />
            </div>
            <div className="clear" />
          </div>
        </div>
        <div className="alt-flight-lr">
          <div className="padding">
            <div className="flt-i-price">634.24$</div>
            <div className="flt-i-price-b">avg/person</div>
            <a className="cat-list-btn" href="http://google.com">
              select now
            </a>
          </div>
          <div className="clear" />
        </div>
        <div className="clear" />
        <div
          className="alt-details "
          style={{ display: this.state.detail === true ? "block" : "none" }}
        >
          <Tabs>
            <Tab label="Details" content="Content1">
                <Segments journeys={this.props.journeys} />
            </Tab>
            <Tab label="Header 2" content="Content2">
              <div className="alt-details-i">
                <b>Connections</b>
                <span>Berlin, Germany</span>
              </div>
              <div className="alt-details-i">
                <b>Flight LO-98 Boeing 787-8 (Jet) Economy</b>
                <span>Operated by Austrian Airlines</span>
              </div>
            </Tab>
            <Tab label="Header 3" content="Content3" />
            <Tab label="Header 4" content="Content4" />
          </Tabs>

          <div className="clear" />
        </div>
      </div>
    );
  }
}

export default FlightOption;
